import React from 'react'
import { serviceDetails } from '../../utils/home'
import styles from './Services.module.scss'

const Services = ({ classes = {} }) => {
  return (
    <div className={`${styles['service-wrapper']} ${classes.container}`}>
      <div className={styles['service-container']}>
        {serviceDetails.map((serviceDetail, index) => {
          return <div key={index} className={styles['service']}>
            <div className={styles['icon-bg']}>
              {serviceDetail.icon}
            </div>
            <h4 className={`mt16 mb16 ${styles['service-heading']}`}>
              {serviceDetail.title}
            </h4>
            <p dangerouslySetInnerHTML={{ __html: serviceDetail.desc }} />
          </div>
        })}
      </div>
    </div>
  )
}

export default Services