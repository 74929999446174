import React from 'react'
import styles from './InclusionsAndExclusions.module.scss'
import { ReactComponent as Tick } from '../../assets/images/tick.svg'
import { ReactComponent as Cross } from '../../assets/images/red-cross.svg'

const iconsMapping = {
  inclusion: <Tick className={styles['tick']} />,
  exclusion: <Cross className={styles['cross']} />,
}
const Inclusions = ({ inclusionsAndExclusionsList }) => {
  return inclusionsAndExclusionsList.map((lists) => (
    <React.Fragment key={lists.id}>
      <div className={styles['heading']}>{lists.heading}</div>
      <ul className={styles['list']}>
        {lists.list.map((list, index) => (
          <li key={index} className={`d-flex`}>
            {iconsMapping[lists.id]} {list}
          </li>
        ))}
      </ul>
    </React.Fragment>
  ))
}

export default Inclusions
