import { ReactComponent as Route } from "../assets/images/route.svg"
import { ReactComponent as Camera } from "../assets/images/camera.svg"
import { ReactComponent as Binoculars } from "../assets/images/binoculars.svg"
import { ReactComponent as Directions } from "../assets/images/direction.svg"

export const serviceDetails = [
  {
    title: 'Uncover Hidden Gems!',
    desc: `Hidden waterfalls, lakes, etc.<br>
    Cusine which you never herd of. <br>
    A photo that captures all.
    `,
    icon: <Camera />
  },
  {
    title: 'Adventure Awaits You!',
    desc: `Long hikes, surfing, river crossing. <br>
    May be it's time to do it all. <br>
    Life is too short to spend on desk.
    `,
    icon: <Binoculars />
  },
  {
    title: 'Want the best experience?',
    desc: `Hotels? Travel? Bookings? <br>
    We've got you all covered! <br>
    You just enjoy the fun part.
    `,
    icon: <Directions />
  }
]

export const reachDetails = [
  {
    number: 500,
    desc: 'Trips and tours',
    icon: <Route />
  },
  {
    number: 30,
    desc: 'Countries',
    icon: <Route />
  },
  {
    number: 50,
    desc: 'Outdoor Activities',
    icon: <Route />
  }, {
    number: '50K',
    desc: 'Happy Nomads',
    icon: <Route />
  }
]