import React from 'react'
import { tripDetails } from '../../../utils/trips'
import TripCard from '../../../common/TripCard/TripCard'
import styles from './AllTrips.module.scss'
import data from '../../../utils/textContent.json'

const AllTrips = () => {
  return (
    <>
      <div className={styles['trips-wrapper']}>
        <h1 className='ml16 mb8 bold'>
          {data.trip.activeTrip}
        </h1>
        <div className='d-flex'>
          <div className={styles['trips-card-container']}>
            {
              tripDetails.filter((trip) => trip.status === 'active').map((trip) => <TripCard key={trip.code} secondDesign={true} trip={trip} classes={{ heading: 'f24 bold' }} />)
            }
          </div>
        </div>
        <h1 className={`ml16 mb8 ${styles['upcoming-trip']} bold`}>
          {data.trip.upcomingTrip}
        </h1>
        <div className='d-flex'>
          <div className={styles['trips-card-container']}>
            {
              tripDetails.filter((trip) => trip.status === 'upcoming').map((trip) => <TripCard key={trip.code} secondDesign={true} trip={trip} classes={{ heading: `${styles['upcoming-trip-heading']} f24 bold` }} />)
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AllTrips