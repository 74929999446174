import React from 'react'
import styles from './TripCard.module.scss'
import { ReactComponent as Clock } from '../../assets/images/clock.svg'
import data from '../../utils/textContent.json'
import { Button } from 'pinitro-react-components'
import { Link, useNavigate } from 'react-router-dom'

const TripCard = ({ trip, secondDesign, classes = {} }) => {
  const navigate = useNavigate()
  const naviagteToDetailsPage = (e) => {
    trip.status !== 'active' &&
      e.preventDefault()
  }
  const naviagteToDetailsPageButton = () => {
    trip.status === 'active' &&
      navigate(`/trips/${trip.code}`)
  }
  return (
    <div className={styles['trip-card']}>
      <div key={trip.title} className={styles['trip-image-container']}>
        <img className={`${styles['trip-image']} ${trip.status === 'upcoming' ? styles['black-and-white-img'] : ''}`} src={trip.img} alt={trip.title} onClick={naviagteToDetailsPageButton} />
      </div>
      <div className={`${styles['trip-desc-container']} ${secondDesign ? 'p16' : 'p24'}`}>
        <Link to={trip.status === 'active' && `/trips/${trip.code}`} className={`mb16 ${styles['trip-heading']} ${classes.heading}`} onClick={naviagteToDetailsPage}>{trip.title}</Link>
        {secondDesign && <div className='d-flex align-center justify-between mb12'>
          <div className={`d-flex align-center`}>
            <Clock className={`mr8 ${styles['clock']} ${trip.status === 'upcoming' ? styles['black-and-white-img'] : ''}`} />
            <span className={styles['light-gray']}>
              {trip.noOfDays} {data.home.days} {' - '} {trip.noOfNights} {data.home.nights}
            </span>
          </div>
          <span className={`${styles['price']} bold f28 color-gray`}><span dangerouslySetInnerHTML={{ __html: trip.currencyIcon }} />{' '}
            {trip.price}</span>
        </div>}
        <div className={`color-gray ${styles['trip-desc']}`}>{trip.desc}</div>
        {secondDesign ? trip.status === 'active' ?
          <div className='pt16 w-100'>
            <Button className='button button-primary w-100' text={data.trip.viewDetails.toUpperCase()} handleOnClick={naviagteToDetailsPageButton} />
          </div> : ""
          :
          <>
            <hr />
            <div className='d-flex align-end justify-between'>
              <div className='d-flex align-center'>
                <Clock className={`mr8 ${styles['clock']}`} />
                <span className={styles['light-gray']}>
                  {trip.noOfDays} {data.home.days}
                </span>
              </div>
              <span className='bold f24'><span dangerouslySetInnerHTML={{ __html: trip.currencyIcon }} />{' '}
                {trip.price}</span>
            </div>
          </>

        }
      </div>

    </div>
  )
}

export default TripCard