import React from 'react'
import styles from './Home.module.scss'
import data from '../../utils/textContent.json'
import { tripDetails } from '../../utils/trips'
import { Button } from 'pinitro-react-components'
import { useNavigate } from 'react-router-dom'
// import Reach from '../../common/Reach/Reach'
import Services from '../../common/Services/Services'
import TripCard from '../../common/TripCard/TripCard'

const Home = () => {
  const navigate = useNavigate()
  return (
    <div>
      <div className={styles['home-banner']}>
        <div className={styles['backdrop']} />
        <div className={styles['banner-text']}>
          {data.home.bannerText}
          <h3 className='text-center'>
            {data.home.bannerSubText}
          </h3>
        </div>
      </div>
      <Services />
      <div className={styles['all-trips-wrapper']}>
        <div className={`${styles['all-trips-container']} flex-column d-flex align-center justify-center`}>
          <div className={`primary bold f24 secondary-font`}>
            {data.home.popularTrips}
          </div>
          <h1 className='mt12 mb24'>
            {data.home.allTripsSubHeading}
          </h1>
          <p className='color-gray f18 text-center'>{data.home.allTripsDesc}</p>
          <div>
          </div>
        </div>
      </div>
      <div className={styles['trips-card-container']}>
        {
          tripDetails.filter((trip) => trip.status === 'active').filter((_, index) => index < 3).map((trip) => <TripCard key={trip.code} trip={trip} />)
        }
      </div>
      <div className={`${styles['button']} w-100 d-flex justify-center`}>
        <Button type='button' text={data.home.viewAll} classes='button-ghost button' handleOnClick={() => { navigate('/trips') }} classContent='f16 weight100' />
      </div>
      {/* <Reach />/ */}
    </div>
  )
}

export default Home