import React from 'react'
import { Footer as FooterComponent } from 'pinitro-react-components'
import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <FooterComponent className={{ footerContainer: 'justify-center' }}>
      <div className={styles['footer-container']}>

      </div>
      © Copyright 2023 Pinitros
    </FooterComponent>
  )
}

export default Footer