import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Header as HeaderComponent } from 'pinitro-react-components'
import styles from './Header.module.scss'
import logo from '../../assets/images/logo.png'
const routes = [
  {
    title: 'Home',
    route: '/home',
    icon: '',
    isActive: false,
  },
  {
    title: 'Trips',
    route: '/trips',
    icon: '',
    isActive: false,
  },
  {
    title: 'About Us',
    route: '/about-us',
    icon: '',
    isActive: false,
  },
]
const Header = () => {
  const [navItems, setNavItems] = useState([])
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const newRoute = routes.map((route) => ({
      ...route, isActive: location.pathname.includes(route.route), onClick: () => {
        navigate(route.route)
        document.body.style.overflowY = 'auto'
      }
    }))

    setNavItems(newRoute)
  }, [location.pathname, navigate])

  return (
    <HeaderComponent className={{ header: styles['header'], navLink: styles['header-text'], container: styles['header-container'] }} position='sticky-top' brand={
      <img src={logo} alt='pinitros-logo' onClick={() => {
        navigate('/')
      }} className={styles['header-logo']} />

    } navItems={navItems} />
  )
}

export default Header