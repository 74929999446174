import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AllTrips from './AllTrips/AllTrips'
import TripDetails from './TripDetails/TripDetails'

const Trips = () => {
  return (
    <Routes>
      <Route index element={<AllTrips />} />
      <Route path=':tripCode' element={<TripDetails />} />
    </Routes>

  )
}

export default Trips