import React, { useEffect, useState } from 'react'
import styles from './Faq.module.scss'
import { ReactComponent as Minus } from '../../assets/images/minus-circle.svg'
import { ReactComponent as Plus } from '../../assets/images/plus-circle.svg'
import { Accordion } from 'pinitro-react-components'
import { ReactComponent as Tick } from '../../assets/images/tick.svg'
import { ReactComponent as Cross } from '../../assets/images/red-cross.svg'


const Faq = ({ faqList }) => {
  const [showAccordion, setShowAccordion] = useState([])

  const toggleAccordion = (i) => {
    setShowAccordion((state) =>
      state.map((item, index) => ([i].includes(index) ? !item : item))
    )
  }

  useEffect(() => {
    const showAccordionInitial = faqList?.map(() => (false))
    setShowAccordion(showAccordionInitial)
  }, [faqList])


  return (
    faqList?.map((faq, index) => <Accordion key={index} id={index} showAccordion={showAccordion && showAccordion[index]} onClick={() => { toggleAccordion(index) }}
      title={<>
        {index === 1 ? <Cross /> : <Tick />}
        <p className={`d-flex align-start`}>
          {faq.que}
        </p>
      </>
      }
      className={{ button: 'w-100 p0', container: `${styles['faqList-accordion-container']} pt24 pb24` }}
      closeIcon={<Minus className={styles['plus']} />}
      openIcon={<Plus className={styles['plus']} />}
    >
      <p className={styles['faqList-desc']} dangerouslySetInnerHTML={{ __html: faq.ans }} />
    </Accordion>)
  )
}

export default Faq