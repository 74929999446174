import React from 'react'
// import Reach from '../../common/Reach/Reach'
import Services from '../../common/Services/Services'
import styles from './AboutUs.module.scss'
import data from '../../utils/textContent.json'
import routeImg from '../../assets/images/about-us-dash-route.png'
import pinsOnMap from '../../assets/images/pins-on-map.jpg'
import { ReactComponent as Eye } from '../../assets/images/eye.svg'
import { ReactComponent as DartBoard } from '../../assets/images/dart-board.svg'
import { ReactComponent as Diamond } from '../../assets/images/diamond.svg'

const AboutUs = () => {
  return (
    <div className='d-flex flex-column'>
      <div className={styles['banner']}>
        <div className={styles['backdrop']} />
        <div className={styles['banner-text-wrapper']}>
          <div className={styles['banner-text']}>
            {data.about.aboutUs}
          </div>
        </div>
      </div>
      <div className={`${styles['details-wrapper']} align-center`}>
        <div className={styles['details-container']}>
          <h6 className='f24 secondary-font primary'>{data.about.aboutPinitros}</h6>
          <h4 className='bold f32'>{data.about.subHeading}</h4>
          <p className='color-gray mt40' dangerouslySetInnerHTML={{ __html: data.about.desc }} />
        </div>
        <div className={`${styles['img-container']}`}>
          <img src={pinsOnMap} alt='pins on map' className={styles['main-img']} />
          <div className={styles['image-bg']} />
        </div>

      </div>
      <Services classes={{ container: styles['service-container'] }} />
      <div className={styles['mission-vision-wrapper']}>
        <div className={styles['container']}>
          <img src={routeImg} alt='' className={styles['route-img']} />
          <div className={styles['left-container']}>
            <h6 className='f24 secondary-font primary'>{data.about.ourCulture}</h6>
            <h2 className='f40 bold mt12 mb28'>{data.about.gatheringMoments}</h2>
            <p className={`color-gray mt28`}>
              {data.about.ourCultureDesc}
            </p>
          </div>
          <div className={styles['right-container']}>
            <div className={styles['mission-details-container']}>
              <div className={styles['mission-vision-cards']}>
                <div className='mb24'>
                  <Eye />
                </div>
                <div>
                  <h3 className={`mt10 ${styles['mission-vision-heading']}`}>{data.about.mission}</h3>
                  <p className='color-gray'>{data.about.missionText}</p>
                </div>
              </div>
            </div>
            <div className={styles['vision-details-container']}>
              <div className={`mb32 ${styles['mission-vision-cards']}`}>
                <div className='mb24'>
                  <DartBoard />
                </div>
                <div>
                  <h3 className={`mt10 ${styles['mission-vision-heading']}`}>{data.about.vision}</h3>
                  <p className='color-gray'>{data.about.visionText}</p>
                </div>
              </div>
              <div className={styles['mission-vision-cards']}>
                <div className='mb24'>
                  <Diamond />
                </div>
                <div>
                  <h3 className={`mt10 ${styles['mission-vision-heading']}`}>{data.about.values}</h3>
                  <p className='color-gray'>{data.about.valuesText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Reach /> */}
    </div>
  )
}

export default AboutUs