import React, { useEffect, useState } from 'react'
import styles from './Itinerary.module.scss'
import { ReactComponent as Minus } from '../../assets/images/minus-circle.svg'
import { ReactComponent as Plus } from '../../assets/images/plus-circle.svg'
import { ReactComponent as Marker } from '../../assets/images/itinerary-marker.svg'
import { ReactComponent as Flag } from '../../assets/images/itinerary-flag.svg'
import { Accordion } from 'pinitro-react-components'

const itineraryIcons = {
  start: <Marker className={styles['itinerary-icon']} />,
  middle: <Flag className={styles['itinerary-middle-icon']} />,
  end: <Flag className={styles['itinerary-icon']} />
}


const Itinerary = ({ itinerary }) => {
  const [showAccordion, setShowAccordion] = useState([])

  const toggleAccordion = (i) => {
    setShowAccordion((state) =>
      state.map((item, index) => ([i].includes(index) ? !item : item))
    )
  }

  useEffect(() => {
    const showAccordionInitial = itinerary?.map(() => (false))
    setShowAccordion(showAccordionInitial)
  }, [itinerary])


  return (
    itinerary?.map((itineraryDetail, index) => <Accordion key={index} id={index} showAccordion={showAccordion && showAccordion[index]} onClick={() => { toggleAccordion(index) }}
      title={<p className={`${styles['itinerary-heading']} d-flex align-start`}>{itineraryIcons[itineraryDetail.img]}
        {`Day ${index + 1} : ${itineraryDetail.title}`}
      </p>}
      className={{ button: 'w-100 p0', container: `${styles['itinerary-accordion-container']} pt24 pb24` }}
      closeIcon={<Minus className={styles['plus']} />}
      openIcon={<Plus className={styles['plus']} />}
    >
      <p className={styles['itinerary-desc']} dangerouslySetInnerHTML={{ __html: itineraryDetail.desc }} />

      {itineraryDetail.img !== 'end' && <div className={styles['border-bottom-accordion']} />
      }
    </Accordion>)
  )
}

export default Itinerary