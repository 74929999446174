import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './common/Layout/Layout';
import { Suspense, useEffect } from 'react';
import Home from './routes/Home/Home';
import AboutUs from './routes/AboutUs/AboutUs';
import Trips from './routes/Trips/Trips';
import { capitalizeFirstLetter } from './utils/functions';

function App() {
  const location = useLocation()

  useEffect(() => {
    const name = `Pinitros ${(location.pathname.includes('home') || location.pathname.split('/')[1] === '') ? '' : '- ' + capitalizeFirstLetter(location.pathname.split('/')[1])}`
    document.title = name
  }, [location.pathname])
  return (
    <Suspense>
      <Routes>
        <Route element={<Layout />} >
          <Route path='*' element={<Navigate to='/home' replace />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about-us' element={< AboutUs />} />
          <Route path='/trips/*' element={< Trips />} />
        </Route>
        <Route path='/'
          element={<Navigate to='home' />}
        />
      </Routes>
    </Suspense>
  );
}

export default App;
