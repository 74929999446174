import React, { useEffect } from 'react'
import Header from '../Header/Header'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Footer from '../Footer/Footer'
import { ReactComponent as Whatsapp } from '../../assets/images/whatsapp.svg'
import styles from './Layout.module.scss'
import data from '../../utils/textContent.json'
const Layout = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <>
      <Header />
      <Outlet />
      {!location.pathname.includes('/trips/') && < Link to='https://api.whatsapp.com/send/?phone=919560575332&text&type=phone_number&app_absent=0' target='_blank' className={styles['whatsapp-container']}>
        <p className={styles['whatsapp-text']}>
          {data.common.whatsappUs}
        </p>
        <div className={styles['whatsapp']}>
          <Whatsapp />
        </div>
      </ Link>}
      <Footer />
    </>
  )
}

export default Layout