import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { tripDetails } from '../../../utils/trips';
import styles from './TripDetails.module.scss'
import data from '../../../utils/textContent.json'
import { capitalizeFirstLetter } from '../../../utils/functions';
import { Carousel, Card } from 'pinitro-react-components'
import { ReactComponent as Accomodation } from '../../../assets/images/accomodation.svg'
import { ReactComponent as DepartureCity } from '../../../assets/images/departure-city.svg'
import { ReactComponent as Activites } from '../../../assets/images/activities.svg'
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg'
import Itinerary from '../../../components/Itinerary/Itinerary';
import Inclusions from '../../../components/InclusionsAndExclusions/InclusionsAndExclusions';
import Faq from '../../../components/FAQ/Faq';


const TripDetails = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 768)
  const { tripCode } = useParams();
  const [selectedTrip, setSelectedTrip] = useState()
  const [showPriceDetails, setShowPriceDetails] = useState(window.innerWidth < 768 ? false : true)
  const [activeTab, setActiveTab] = useState(0)

  const tabsRef = useRef([]);

  const navigate = useNavigate()

  useEffect(() => {
    const handleOnResize = () => {
      setShowPriceDetails(window.innerWidth < 768 ? false : true)
      setIsMobileScreen(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleOnResize)
    return () => {
      window.removeEventListener('resize', handleOnResize)
    }
  }, [])

  const detailSpecificComponent = {
    itinerary: <Itinerary itinerary={selectedTrip?.tripRelatedDetails.itinerary.itineraryDetails} />,
    inclusionsAndExclusions: <Inclusions inclusionsAndExclusionsList={selectedTrip?.tripRelatedDetails.inclusionsAndExclusions.lists} />,
    faq: <Faq faqList={selectedTrip?.tripRelatedDetails.faq.faqList} />
  }
  useEffect(() => {
    const selectedTripDetails = tripDetails.find((trip) =>
      trip.code === tripCode
    )
    selectedTripDetails ? setSelectedTrip(selectedTripDetails) : navigate('/trips')
  }, [tripCode, navigate])

  const scrollToSection = (index) => {

    const element = tabsRef.current[index];
    window.scrollTo({
      top: element.offsetTop - 105,
      behavior: 'smooth',
    })
  }

  const handleOnTabChange = (index) => {
    setActiveTab(index)
    scrollToSection(index)
  }

  useEffect(() => {
    const handleOnScroll = () => {
      const rect = tabsRef.current[0]?.getBoundingClientRect();
      if (rect) {
        const elementInViewPort =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        if (elementInViewPort) setActiveTab(0)
      }
    }
    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };

  }, [])

  return selectedTrip ? (
    <>

      <div className={styles['wrapper']} >
        <div className={styles['left-container']}>
          <div className={`d-flex justify-between align-center ${styles['left-container-header']}`}>
            <h1>
              {selectedTrip?.title}
            </h1>
            <div className={styles['days-container']}>
              <div className={styles['days-number']}>
                {selectedTrip?.noOfDays}
              </div>
              <div className={styles['days']}>
                {capitalizeFirstLetter(data.common.days)}
              </div>
            </div>
          </div>
          <div className={`${styles['image-container']}`}>
            <Carousel items={selectedTrip?.images || []}
              showArrows={false}
              className={{ container: styles['carousel-container'] }}
              showFullImages={true}
              showDots={false}
              individualBanner={
                (item) => <img src={item} alt='img' className={styles['image']} />
              } />
          </div>
          <p className='normal-text mb40' dangerouslySetInnerHTML={{ __html: selectedTrip?.detailedDesc }} />
          {isMobileScreen && <Card className={`p24`}>
            <h2 className='f32 bold mb16'>
              {data.trip.tripFacts}
            </h2>
            <div className='mt16 mb16'>
              <Accomodation className={styles['accomodation-icon']} />
              <span className={styles['additional-details-heading']}>{data.trip.accomodation}</span>
              <div className='mt4 f16'>{selectedTrip?.accomodationStar} {data.trip.starHotel}</div>
            </div>
            <div className='mt16 mb16'>
              <DepartureCity className={styles['departure-icon']} />
              <span className={styles['additional-details-heading']}>{data.trip.departureCity}</span>
              <div className='mt4 f16'>{selectedTrip?.departureCity}</div>
            </div>
            <div className='mt16 mb16'>
              <Activites className={styles['departure-icon']} />
              <span className={styles['additional-details-heading']}>{data.trip.activities}</span>
              <div className='mt4 f16'>{selectedTrip?.activites?.join(', ')}</div>
            </div>
          </Card>}
          <div className={`mt20 ${styles['details-card']}`}>
            <div className={`${styles['details-header-container']} w-100 d-flex`}>
              {Object.values(selectedTrip?.tripRelatedDetails).map((item, index) => item.show ?
                <p key={item.id} onClick={() => { handleOnTabChange(index) }} className={`${styles['details-header']} ${index === activeTab ? styles['details-header--active'] : ''}`}>
                  {item.displayName}
                </p> : ''
              )}
            </div>
            {Object.values(selectedTrip?.tripRelatedDetails).map((item, index) =>
              item.show ? <div key={item.id} ref={(el) => tabsRef.current[index] = el} className={styles['details-container']}>
                <h2 className={styles['details-heading']}>
                  {item.displayName}
                </h2>
                {
                  detailSpecificComponent[item.identifierName]
                }
              </div> : '')

            }
          </div>
        </div>
        <div className={styles['right-container']}>
          {selectedTrip.showPirceLists && <Card className={`${styles['pricing-container']}`}>
            <div onClick={() => {
              setShowPriceDetails((showPriceDetails) => !showPriceDetails)
            }} className={styles['show-price-button']}>
              {showPriceDetails ? 'Hide' : 'Show'} Prices <Arrow className={`${showPriceDetails ? styles['down-arrow'] : ''} ml8`} />
            </div>
            <div className={`${styles['slide-up']} ${styles['show-price']} ${!showPriceDetails ? styles['hide-price'] : ""} mb24`}>
              <div className={styles['pricing-details']}>
                {data.common.from}
                <div>
                  <span className={`${styles['price']} f24`} dangerouslySetInnerHTML={{ __html: selectedTrip?.currencyIcon }} />
                  <span className={`${styles['price']} f24`}> {selectedTrip?.doubleSharingPrice}</span>
                </div>
                <div className='f14'>
                  {data.trip.perAdult}
                </div>
                <div className='f14 black-text' >
                  {data.trip.doubleSharing}
                </div>
              </div>
              <div className={styles['pricing-details']}>
                {data.common.from}
                <div>
                  <span className={`${styles['price']} f24`} dangerouslySetInnerHTML={{ __html: selectedTrip?.currencyIcon }} />
                  <span className={`${styles['price']} f24`}> {selectedTrip?.tripleSharingPrice}</span>
                </div>
                <div className='f14'>
                  {data.trip.perAdult}
                </div>
                <div className='f14 black-text' >
                  {data.trip.tripleSharing}
                </div>
              </div>
            </div>
            <div className='text-center f14'>{data.trip.needHelp} <Link to='https://api.whatsapp.com/send/?phone=919560575332&text&type=phone_number&app_absent=0' target='_blank'>
              {data.trip.sendUsMessage}
            </Link>
            </div>
          </Card>}
          <Card className={`${styles['addition-details-container']} p24`}>
            <h2 className='f32 bold mb16'>
              {data.trip.tripFacts}
            </h2>
            <div className='mt16 mb16'>
              <Accomodation className={styles['accomodation-icon']} />
              <span className={styles['additional-details-heading']}>{data.trip.accomodation}</span>
              <div className='mt4 f16'>{selectedTrip?.accomodationStar} {data.trip.starHotel}</div>
            </div>
            <div className='mt16 mb16'>
              <DepartureCity className={styles['departure-icon']} />
              <span className={styles['additional-details-heading']}>{data.trip.departureCity}</span>
              <div className='mt4 f16'>{selectedTrip?.departureCity}</div>
            </div>
            <div className='mt16 mb16'>
              <Activites className={styles['departure-icon']} />
              <span className={styles['additional-details-heading']}>{data.trip.activities}</span>
              <div className='mt4 f16'>{selectedTrip?.activites?.join(', ')}</div>
            </div>
          </Card>
        </div>
        {isMobileScreen && <Link to='https://api.whatsapp.com/send/?phone=919560575332&text&type=phone_number&app_absent=0' target='_blank' className='button button-primary w-100 mt20 text-center no-text-decoration'>
          {data.trip.contactUsWhatsapp}
        </Link>}
      </div >
    </>
  ) : ('')
}

export default TripDetails