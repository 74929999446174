import beach from '../assets/images/beach.jpeg'
import sunset from '../assets/images/sunset.jpeg'
import us from '../assets/images/about-us-main.jpeg'
import manaliPoster from '../assets/images/manali-poster.jpg'
import goaMain1 from '../assets/images/goa-main-1.jpg'
import goaMain2 from '../assets/images/goa-main-2.jpg'
import ladakhMain from '../assets/images/ladakh.jpg'
import meghalayaMain from '../assets/images/meghalaya-main.jpg'
import goaChurch from '../assets/images/goa-church.jpg'
import goaBeach from '../assets/images/goa-beach.jpg'
import goaBeach2 from '../assets/images/goa-beach-2.jpg'
import goaFontainhas from '../assets/images/goa-fontainhas.jpg'
import manaliTemple from '../assets/images/manali-temple.jpg'
import manaliWaterfall from '../assets/images/manali-jogini-falls.jpg'
import manaliHouses from '../assets/images/manali-houses.jpg'
import manaliWinter from '../assets/images/manali-winter.jpg'
import mh1 from '../assets/images/mh1.jpg'
// import mh2 from '../assets/images/mh2.jpg'
import mh3 from '../assets/images/mh3.jpg'
import mh4 from '../assets/images/mh4.jpg'
import mh5 from '../assets/images/mh5.jpg'
import ml1 from '../assets/images/ml1.jpg'
import ml2 from '../assets/images/ml2.jpg'
import ml3 from '../assets/images/ml3.jpg'
import ml4 from '../assets/images/ml4.jpg'
import ml5 from '../assets/images/ml5.jpg'

export const tripDetails = [
  {
    title: 'Pune to Goa',
    desc: 'Pune to Goa: Western Wonders and Coastal Charms Await!',
    noOfDays: '4',
    showPirceLists: true,
    noOfNights: '4',
    price: 17499,
    currencyIcon: '&#8377;',
    img: goaMain1,
    code: 'gtp1',
    doubleSharingPrice: 17499,
    tripleSharingPrice: 16499,
    accomodationStar: 3,
    departureCity: 'Pune',
    images: [goaBeach, goaChurch, goaFontainhas],
    status: 'active',
    activites: ['Boating', 'Kayaking', 'Scuba Diving'],
    detailedDesc: `
      Ever dreamed of a Pune to Goa trip?<br><br>
      Picture coastlines, lush landscapes, scuba diving adventures, and breathtaking sunsets on the beach—all waiting for your discovery<br><br>
      Immerse yourself in the rich history of Goa before transitioning to the sun-soaked beaches. And hey, who knows? Perhaps you'll dance all night at a beach party!<br><br>
      Feel the allure of pristine beaches, vibrant nightlife, the spirit of adventure, carefree moments with newfound friendships.<br><br>
      Ready to make this dream a reality? Contact us on WhatsApp now to make it an experience of a lifetime!
    `,
    tripRelatedDetails: {
      itinerary: {
        displayName: 'Itinerary',
        identifierName: 'itinerary',
        id: 1,
        show: true,
        itineraryDetails: [
          {
            title: 'Departure from Pune to Goa',
            img: 'start',
            desc: 'On the 24th of November, your journey begins as you depart from Pune, excited for the Goan adventure that awaits you.',
          },
          {
            title: 'Relaxation Day',
            img: 'middle',
            desc: "The 25th of November is dedicated to unwinding and enjoying the pristine Goan beaches. You'll explore Aguada Fort's historic charm, relax on the golden sands of Calangute Beach, experience the lively vibes of Baga Beach, admire the scenic beauty of Anjuna Beach, and bask in the serenity of Vagator Beach.",
          },
          {
            title: 'Adventure Day',
            img: 'end',
            desc: "The 26th of November brings an adrenaline rush as you explore Fontainhas' colorful heritage houses, venture to the beautiful islands off the coast, discover the underwater world through scuba diving, and delight in the playful sight of dolphins.",
          },
        ],
      },
      inclusionsAndExclusions: {
        id: 2,
        show: false,
        displayName: 'Inclusions/ Exclusions',
        identifierName: 'inclusionsAndExclusions',
        lists: [
          { id: 'inclusion', heading: 'The package includes', list: ['x', 'y', 'z'] },
          {
            id: 'exclusion',
            heading: 'The package excludes',
            list: ['a', 'b', 'c'],
          },
        ],
      },
      faq: {
        id: 3,
        identifierName: 'faq',
        show: false,
        displayName: 'FAQ',
        faqList: [
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
        ],
      },
    },
  },
  {
    title: 'Gujrat to Goa',
    desc: 'Goan Delights: Beach Bliss, Heritage Marvels, and Scenic South Exploration!',
    noOfDays: '5',
    showPirceLists: true,
    noOfNights: '5',
    price: 22999,
    currencyIcon: '&#8377;',
    img: goaMain2,
    code: 'gtp2',
    tripRelatedDetails: {
      itinerary: {
        displayName: 'Itinerary',
        identifierName: 'itinerary',

        id: 1,
        show: true,
        itineraryDetails: [
          {
            title: 'Flight from Gujarat to Goa',
            img: 'start',
            desc: `
          8:00 AM: Departure from Gujarat to Goa via flight<br>
          Relax and enjoy a comfortable journey
          10:00 AM: Arrive in Goa<br>
          Check-in at the hotel and freshen up<br>
          Spend the day at a baga beach<br>
          Evening: Explore local beachside shacks
        `,
          },
          {
            title: 'Historic Tour in Old Goa',
            img: 'middle',
            desc: `
          9:00 AM: Visit historic sites in Old Goa<br>
          Explore churches and museums<br>
          Afternoon: Enjoy Goan cuisine for lunch<br>
          Evening: Leisure time for shopping
        `,
          },
          {
            title: 'Exploration in South Goa',
            img: 'middle',
            desc: `
          9:00 AM: Visit Colva Beach for a serene morning<br>
          Explore Cabo de Rama Fort in the afternoon<br>
          Enjoy the tranquility of Palolem Beach in the evening
        `,
          },
          {
            title: 'Adventure at Dudhsagar Waterfalls',
            img: 'middle',
            desc: `
          7:00 AM: Excursion to Dudhsagar Waterfalls<br>
          Adventure activities and scenic beauty<br>
          Return to Goa by late afternoon<br>
          Evening: Relax and unwind
        `,
          },
          {
            title: 'Departure to Gujarat',
            img: 'end',
            desc: `
          09:00 AM: Check-out from the hotel<br>
          10:00 AM: Relax at Agonda Beach<br>
          Visit the Cabo de Rama Beach in the afternoon<br>
          Depart for Gujarat via flight<br>
          Cherish the memories of your Goa adventure
        `,
          },
        ],
      },
      inclusionsAndExclusions: {
        id: 2,
        show: false,
        displayName: 'Inclusions/ Exclusions',
        identifierName: 'inclusionsAndExclusions',
        lists: [
          { id: 'inclusion', heading: 'The package includes', list: ['x', 'y', 'z'] },
          {
            id: 'exclusion',
            heading: 'The package excludes',
            list: ['a', 'b', 'c'],
          },
        ],
      },
      faq: {
        id: 3,
        identifierName: 'faq',
        show: false,
        displayName: 'FAQ',
        faqList: [
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
        ],
      },
    },
    images: [beach, goaBeach2, sunset],
    status: 'active',
    doubleSharingPrice: 22999,
    tripleSharingPrice: 21999,
    accomodationStar: 3,
    departureCity: 'Gujarat',
    activites: ['Boating', 'Kayaking', 'Scuba Diving'],
    detailedDesc: `
      Ever dreamed of a Gujarat to Goa trip?<br><br>
      Picture coastlines, lush landscapes, scuba diving adventures, and breathtaking sunsets on the beach—all waiting for your discovery<br><br>
      Immerse yourself in the rich history of Goa before transitioning to the sun-soaked beaches. And hey, who knows? Perhaps you'll dance all night at a beach party!<br><br>
      Feel the allure of pristine beaches, vibrant nightlife, the spirit of adventure, carefree moments with newfound friendships.<br><br>
      Ready to make this dream a reality? Contact us on WhatsApp now to make it an experience of a lifetime!
    `,
  },
  {
    title: 'Delhi to Manali',
    desc: 'Manali Adventure: Peaks, Thrills, and Himalayan Majesty Awaits!',
    noOfDays: '4',
    noOfNights: '4',
    showPirceLists: true,
    price: 8499,
    currencyIcon: '&#8377;',
    images: [manaliTemple, manaliWaterfall, manaliHouses, manaliWinter],
    status: 'active',
    img: manaliPoster,
    code: 'gtp3',
    tripRelatedDetails: {
      itinerary: {
        displayName: 'Itinerary',
        identifierName: 'itinerary',
        id: 1,
        show: true,
        itineraryDetails: [
          {
            title: 'Departure from Dehi to Manali',
            img: 'start',
            desc: `
              8:00 PM: Departure from Delhi via overnight bus to Manali.<br>
              Get settled, relax, and enjoy the scenic journey
            `,
          },
          {
            title: 'Manali exploration',
            img: 'middle',
            desc: `
              8:00 AM: Arrive in Manali<br>
              Check-in at the hotel and freshen up<br>
              Morning exploration of Old Manali, visit Hadimba Temple<br>
              Afternoon: Leisure time for shopping and local experiences<br>
              Evening: Dinner at a local restaurant
            `,
          },
          {
            title: 'Solan Valley',
            img: 'middle',
            desc: `
              9:00 AM: Full-day excursion to Solang Valley for adventure activities (paragliding, zorbing)<br>
              Picnic lunch amidst stunning landscapes<br>
              Return to Manali in the evening<br>
              Explore Mall Road, indulge in local cuisine
            `,
          },
          {
            title: 'Good bye manali',
            img: 'middle',
            desc: `
              10:00 AM: Visit Vashisht Hot Springs<br>
              Lunch at a local café<br>
              Explore Naggar Castle and Art Gallery<br>
              Evening: Farewell dinner at a scenic spot<br>
              8:00 PM: Departure for Delhi via overnight bus
            `,
          },
          {
            title: 'Back to delhi',
            img: 'end',
            desc: `
              90:00 AM: Reach delhi
            `,
          },
        ],
      },
      inclusionsAndExclusions: {
        id: 2,
        show: true,
        displayName: 'Inclusions/ Exclusions',
        identifierName: 'inclusionsAndExclusions',
        lists: [
          { 
            id: 'inclusion',
            heading: 'The package includes',
            list: [
              'Entire travel as per the itinerary.',
              '4 meals will be provided throughout the trip. 2 Breakfasts + 2 Dinners.',
              'Accommodation for 2 nights in a hotel at Manali.',
              'Team captain throughout the trip.'
            ]
          },
          {
            id: 'exclusion',
            heading: 'The package excludes',
            list: [
              'Any other Food and Beverage charge that is not included in the package.',
              'Any permit or entry tickets to viewpoints.',
              'Anything not mentioned in the inclusions column.'
            ],
          },
        ],
      },
      faq: {
        id: 3,
        identifierName: 'faq',
        show: false,
        displayName: 'FAQ',
        faqList: [
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
        ],
      },
    },

    doubleSharingPrice: 8999,
    tripleSharingPrice: 8499,
    accomodationStar: 3,
    departureCity: 'Delhi, Chandigarh',
    activites: ['Trekking', 'Hiking', 'Bonfire', 'Music night'],
    detailedDesc: `
      Hey wanderlust friend! Ready for an unforgettable journey from Delhi to Manali?<br><br>
      Picture this: majestic mountains, vibrant cultures, and the thrill of adventure!<br><br>
      Immerse yourself in the rich tapestry of local life, from exploring ancient temples to savoring delectable mountain cuisine.<br><br>
      Trek through scenic trails, experience the rush of river rafting, or simply unwind amid breathtaking landscapes. Intrigued?<br><br>
      Visit famaous places like: Hadima Devi Temple, Greate Himalayan National Park, Jogini Falls, Mall Road, Manu Temple<br><br>
      Ready to make this dream a reality? Contact us on WhatsApp now to make it an experience of a lifetime!
    `,
  },
  {
    title: 'Honeymoon in Manali',
    desc: 'Experience the 7 most romantic days of your life.',
    noOfDays: '7',
    noOfNights: '7',
    price: 74999,
    currencyIcon: '&#8377;',
    images: [mh1, mh3, mh4],
    status: 'active',
    img: mh5,
    code: 'mh1',
    showPirceLists: false,
    tripRelatedDetails: {
      itinerary: {
        displayName: 'Itinerary',
        identifierName: 'itinerary',
        id: 1,
        show: true,
        itineraryDetails: [
          {
            title: 'The start',
            img: 'start',
            desc: 'Pickup and overnight journey in private cab from Delhi to Manali',
          },
          {
            title: 'Romantic Dinner Date',
            img: 'middle',
            desc: 'Reach manali<br>Pick up from bus stand to hotel<br>Check-in into your 5 star<br>Rest for few hour<br>Walk in near by area<br>Get ready for your romantic dinner<br>Romantic Dinner in 5 star hotel',
          },
          {
            title: 'Local Exploration',
            img: 'middle',
            desc: 'Mall Road<br>Old Manali<br>Hadimba Devi Temple.<br>Vashisht Hot Springs.<br>Enjoy the evening at leisure, trying local cuisines',
          },
          {
            title: 'Solang Valley Adventure',
            img: 'middle',
            desc: 'Head to Solang Valley for a day.<br>Take in the breathtaking views of the surrounding snow-capped mountains.<br>Optional:  Paragliding, zorbing, and skiing (seasonal).<br>Return to Manali in the evening and relax.',
          },
          {
            title: 'Kasol, Manikaran and Bonfire',
            img: 'middle',
            desc: 'Take a day trip to Manikaran and experience the hot springs and Gurudwara.<br>Visit Kasol, a nearby charming village known for its scenic beauty and cafes.<br>Explore the Parvati River and surroundings before returning to Manali.<br>Return to Manali in the evening<br>Personal Bonfire for you',
          },
          {
            title: 'Kullu',
            img: 'middle',
            desc: 'Checkout from hotel<br>Visit Naggar Castle<br>Nicholas Roerich Art Gallery<br>Bijli Mahadev<br>Spend a relaxing evening in Naggar<br>Overnight bus to delhi',
          },
          {
            title: 'The End',
            img: 'end',
            desc: 'Arrival in Delhi',
          },
        ],
      },
      inclusionsAndExclusions: {
        id: 2,
        show: true,
        displayName: 'Inclusions/ Exclusions',
        identifierName: 'inclusionsAndExclusions',
        lists: [
          {
            id: 'inclusion',
            heading: 'The package includes',
            list: [
              '5 Star Hotel',
              'Private cab for complete trip',
              'Dinner date',
              'Bonfire',
              'Breakfast and Dinner(4 Breakfast and 4 Dinner)',
            ],
          },
          {
            id: 'exclusion',
            heading: 'The package excludes',
            list: [
              'Any extra food or beverage.',
              'Any permit or entry tickets to viewpoints.',
              'Anything not mentioned in the inclusions column.',
            ],
          },
        ],
      },
      faq: {
        id: 3,
        identifierName: 'faq',
        show: true,
        displayName: 'FAQ',
        faqList: [
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
        ],
      },
    },
    accomodationStar: 5,
    departureCity: 'Delhi, Chandigarh',
    activites: ['Sightseeing', 'Dinner date', 'Private Bonfire'],
    detailedDesc: `
    Hey love birds! Embark on an enchanting journey from Delhi to Manali with our 5-star hotel, private cab, intimate dinner, and a cozy bonfire.
    <br><br>
    Experience majestic mountains, vibrant cultures, and the thrill of adventure. Immerse yourselves in local life, from ancient temples to delectable mountain cuisine.
    <br><br>
    Trek scenic trails or simply unwind amid breathtaking landscapes. Discover Hadima Devi Temple, Great Himalayan National Park, Jogini Falls, Mall Road, and Manu Temple.
    <br><br>
    Ready to turn this dream into your honeymoon reality? Contact us on WhatsApp for a once-in-a-lifetime experience!<br><br>
    `,
  },
  {
    title: 'Manali long weekend',
    desc: '4 days experience of winter manali',
    noOfDays: '4',
    noOfNights: '4',
    price: 12999,
    currencyIcon: '&#8377;',
    images: [ml1, ml2, ml3, ml4, ml5],
    status: 'active',
    img: mh5,
    code: 'ml1',
    showPirceLists: false,
    tripRelatedDetails: {
      itinerary: {
        displayName: 'Itinerary',
        identifierName: 'itinerary',
        id: 1,
        show: true,
        itineraryDetails: [
          {
            title: 'The start',
            img: 'start',
            desc: 'Overnight journey from Delhi to Manali',
          },
          {
            title: 'Local Exploration and Bonfire',
            img: 'middle',
            desc: 'Reach manali<br>Local sightseeing<br>Mall Road<br>Old Manali<br>Hadimba Devi Temple<br>Vashisht Hot Springs.<br>Enjoy the bonfire<br>Relax in your comfy room',
          },
          {
            title: 'Solang Valley Adventure',
            img: 'middle',
            desc: 'Head to Solang Valley for a day<br>Take in the breathtaking views of the surrounding snow-capped mountains<br>Optional:  Paragliding, zorbing, and skiing (seasonal)<br>Return to Manali in the evening and relax',
          },
          {
            title: 'Kasol, Manikaran',
            img: 'middle',
            desc: 'Checkout from hotel<br>Day trip to Manikaran and experience the hot springs and Gurudwara<br>Visit Kasol, a nearby charming village known for its scenic beauty and cafes<br>Explore the Parvati River and surroundings<br>Overnight journey to delhi',
          },
          {
            title: 'The End',
            img: 'end',
            desc: 'Arrival in Delhi',
          },
        ],
      },
      inclusionsAndExclusions: {
        id: 2,
        show: true,
        displayName: 'Inclusions/ Exclusions',
        identifierName: 'inclusionsAndExclusions',
        lists: [
          {
            id: 'inclusion',
            heading: 'The package includes',
            list: [
              '4 Star Hotel',
              'Sightseeing',
              'Transportation from Delhi to Manali and back',
              'Bonfire',
              'Breakfast and Dinner(2 Breakfast and 2 Dinner)',
            ],
          },
          {
            id: 'exclusion',
            heading: 'The package excludes',
            list: [
              'Any extra food or beverage.',
              'Any permit or entry tickets to viewpoints.',
              'Anything not mentioned in the inclusions column.',
            ],
          },
        ],
      },
      faq: {
        id: 3,
        identifierName: 'faq',
        show: false,
        displayName: 'FAQ',
        faqList: [
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
        ],
      },
    },
    accomodationStar: 4,
    departureCity: 'Delhi, Chandigarh',
    activites: ['Sightseeing', 'Bonfire'],
    detailedDesc: `
      Hey wanderlust friend! Ready for an unforgettable journey from Delhi to Manali?<br><br>
      Picture this: majestic mountains, vibrant cultures, and the thrill of adventure!<br><br>
      Immerse yourself in the rich tapestry of local life, from exploring ancient temples to savoring delectable mountain cuisine.<br><br>
      Trek through scenic trails, experience the rush of river rafting, or simply unwind amid breathtaking landscapes. Intrigued?<br><br>
      Visit famaous places like: Hadima Devi Temple, Greate Himalayan National Park, Jogini Falls, Mall Road, Manu Temple<br><br>
      Ready to make this dream a reality? Contact us on WhatsApp now to make it an experience of a lifetime!
    `,
  },
  {
    title: 'Ladakh',
    desc: 'Ladakh Marvel: Peaks, Tranquility, and Himalayan Grandeur Beckons You!',
    noOfDays: '5',
    noOfNights: '5',
    price: 17499,
    currencyIcon: '&#8377;',
    img: ladakhMain,
    status: 'upcoming',
    code: 'gtp4',
    doubleSharingPrice: 17499,
    tripleSharingPrice: 16499,
    images: [beach, us, sunset],
    accomodationStar: 3,
    departureCity: 'Pune',
    activites: ['Boating', 'Kayaking', 'Scuba Diving'],
    detailedDesc:
      'Embark on a captivating journey from Pune to Goa, where every day promises a new adventure and unforgettable memories. This five-day itinerary is carefully crafted to offer a blend of relaxation, adventure, cultural exploration, and hidden gems.',
    tripRelatedDetails: {
      itinerary: {
        displayName: 'Itinerary',
        identifierName: 'itinerary',
        id: 1,
        show: true,
        itineraryDetails: [
          {
            title: 'Flight from Gujarat to Goa',
            img: 'start',
            desc: `
      8:00 AM: Departure from Gujarat to Goa via flight<br>
      Relax and enjoy a comfortable journey
      10:00 AM: Arrive in Goa<br>
      Check-in at the hotel and freshen up<br>
      Spend the day at a baga beach<br>
      Evening: Explore local beachside shacks
    `,
          },
          {
            title: 'Historic Tour in Old Goa',
            img: 'middle',
            desc: `
      9:00 AM: Visit historic sites in Old Goa<br>
      Explore churches and museums<br>
      Afternoon: Enjoy Goan cuisine for lunch<br>
      Evening: Leisure time for shopping
    `,
          },
          {
            title: 'Exploration in South Goa',
            img: 'middle',
            desc: `
      9:00 AM: Visit Colva Beach for a serene morning<br>
      Explore Cabo de Rama Fort in the afternoon<br>
      Enjoy the tranquility of Palolem Beach in the evening
    `,
          },
          {
            title: 'Adventure at Dudhsagar Waterfalls',
            img: 'middle',
            desc: `
      7:00 AM: Excursion to Dudhsagar Waterfalls<br>
      Adventure activities and scenic beauty<br>
      Return to Goa by late afternoon<br>
      Evening: Relax and unwind
    `,
          },
          {
            title: 'Departure to Gujarat',
            img: 'end',
            desc: `
      09:00 AM: Check-out from the hotel<br>
      10:00 AM: Relax at Agonda Beach<br>
      Visit the Cabo de Rama Beach in the afternoon<br>
      Depart for Gujarat via flight<br>
      Cherish the memories of your Goa adventure
    `,
          },
        ],
      },
      inclusionsAndExclusions: {
        id: 2,
        show: false,
        displayName: 'Inclusions/ Exclusions',
        identifierName: 'inclusionsAndExclusions',
        lists: [
          { id: 'inclusion', heading: 'The package includes', list: ['x', 'y', 'z'] },
          {
            id: 'exclusion',
            heading: 'The package excludes',
            list: ['a', 'b', 'c'],
          },
        ],
      },
      faq: {
        id: 3,
        identifierName: 'faq',
        show: false,
        displayName: 'FAQ',
        faqList: [
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
        ],
      },
    },
  },
  {
    title: 'Meghalaya',
    desc: 'Meghalaya Magic: Immerse in Waterfalls, Culture, and Serenity Unveiled!',
    noOfDays: '5',
    showPirceLists: true,
    noOfNights: '5',
    price: 17499,
    currencyIcon: '&#8377;',
    img: meghalayaMain,
    code: 'gtp5',
    doubleSharingPrice: 17499,
    tripleSharingPrice: 16499,
    images: [beach, us, sunset],
    accomodationStar: 3,
    status: 'upcoming',
    departureCity: 'Pune',
    activites: ['Boating', 'Kayaking', 'Scuba Diving'],
    detailedDesc:
      'Embark on a captivating journey from Pune to Goa, where every day promises a new adventure and unforgettable memories. This five-day itinerary is carefully crafted to offer a blend of relaxation, adventure, cultural exploration, and hidden gems.',
    tripRelatedDetails: {
      itinerary: {
        displayName: 'Itinerary',
        identifierName: 'itinerary',

        id: 1,
        show: true,
        itineraryDetails: [
          {
            title: 'Departure from Pune to Goa',
            desc: 'On the 24th of November, your journey begins as you depart from Pune, excited for the Goan adventure that awaits you.',
            img: 'start',
          },
          {
            title: 'Relaxation Day',
            img: 'middle',
            desc: "The 25th of November is dedicated to unwinding and enjoying the pristine Goan beaches. You'll explore Aguada Fort's historic charm, relax on the golden sands of Calangute Beach, experience the lively vibes of Baga Beach, admire the scenic beauty of Anjuna Beach, and bask in the serenity of Vagator Beach.",
          },
          {
            title: 'Adventure Day',
            img: 'end',
            desc: "The 26th of November brings an adrenaline rush as you explore Fontainhas' colorful heritage houses, venture to the beautiful islands off the coast, discover the underwater world through scuba diving, and delight in the playful sight of dolphins.",
          },
        ],
      },
      inclusionsAndExclusions: {
        id: 2,
        show: false,
        displayName: 'Inclusions/ Exclusions',
        identifierName: 'inclusionsAndExclusions',
        lists: [
          { id: 'inclusion', heading: 'The package includes', list: ['x', 'y', 'z'] },
          {
            id: 'exclusion',
            heading: 'The package excludes',
            list: ['a', 'b', 'c'],
          },
        ],
      },
      faq: {
        id: 3,
        identifierName: 'faq',
        displayName: 'FAQ',
        show: false,
        faqList: [
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
          {
            que: 'asdsf',
            ans: 'fsdf',
          },
        ],
      },
    },
  },
]
